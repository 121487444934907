.slider-circle {
  background: #6a5e57;
  border-radius: 100%;
  height: 3.125em;
  width: 3.125em;
  padding: 0;
  position: relative;
  &:hover {
    background: #574d48;
  }
  #center {
    width: 100%;
  }
  #arrow {
    transform: rotate(45deg);
    position: absolute;
    right: 1.4em;
  }
}
.slider-container {
  padding: 0 !important;
  .top {
    height: 10px;
    width: 10px;
    transform: rotate(90deg);
    position: absolute;
  }
  .bottom {
    height: 10px;
    width: 10px;
  }
}
.slider-circle {
  cursor: pointer;
}
.slider-container,
.slider-circle,
.slider {
  display: flex;
  align-items: center;
}
.slider-container,
.slider-line {
  width: 100%;
}
.slider {
  strong {
    width: 4em;
    font-size: 20px;
  }
  @media screen and (max-width: 600px) {
    strong {
      width: 6em;
    }
  }
}
