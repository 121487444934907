.footer-page {
  background: #22201d;
  color: #fff5ec;
  display: flex;
  flex-direction: column;
  .row {
    margin: auto;
  }
  .footer-body {
    padding: 5% 9% 5% 9%;
    flex: 1;
    .add-gap {
      display: flex;
      flex-direction: column;
      gap: 3em;
    }
  }
  .footer-footer {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 2em 0em;
    border-top: 1px solid #bcb6b2;

    .footer-mandatory-links {
      display: flex;
      gap: 2em;
    }

    .footer-mandatory-links span {
      color: #fff5ec;
    }
  }
}

.pages-services-component {
  width: 100%;
  flex-wrap: wrap;
  display: flex;
  justify-content: space-around;

  .responsiveness {
    display: flex;
    flex-wrap: wrap;
    strong {
      width: 6.125em;
    }
  }
}

$mandatory-link-sm: 350px;
@media screen and (max-width: $mandatory-link-sm) {
  .footer-footer {
    .footer-mandatory-links {
      display: flex;
      flex-direction: column;
      gap: 0em !important;
    }
  }
}

// .social-media-icons {
//   height: 4.375em;
//   width: 4.375em;
//   border-radius: 100%;
//   border: 1px solid #ffdbb9;
//   .icon {
//     font-size: 1.5em;
//   }
// }
