.custom-carousel {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  position: relative;

  .custom-carousel-inner {
    white-space: nowrap;
    transition: transform 0.5s ease-in-out;
  }

  .custom-carousel-item {
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }

  .custom-carousel-expand-image-button {
    display: flex;
    padding: 0;
    justify-content: center;
    align-items: center;
    position: absolute;
    font-size: 1.5em;
    -webkit-text-stroke-width: 0.8px;
    width: 2.2em;
    height: 2.2em;
    top: 0.5em;
    right: 0.5em;
    border-radius: 100%;
  }

  .custom-carousel-indicators {
    position: absolute;
    bottom: 1.5em;
    display: flex;
    width: 100%;
    justify-content: center;
    .custom-carousel-navigator {
      background: none;
      border: none;
      i {
        -webkit-text-stroke-width: 2px;
        color: #3a3a3a;
        font-size: 1.8em;
      }
    }
    &img {
      background: none;
      outline: none;
    }
    & > button {
      border-radius: 0;
      border-color: #3c342c;
      background: #3c342c;
      height: 5em;
      width: 5em;
      padding: 1px;
      margin: 5px;
      &:hover {
        background-color: #ffdbb9;
        border-color: #ffdbb9;
      }
    }
    & > button.active {
      background-color: #ffdbb9;
      border-color: #ffdbb9;
      background: #ffdbb9;
      color: #fff;
    }
  }
}
