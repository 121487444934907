.contact-page {
  padding: 0% 9%;
  header {
    font-weight: 600;
  }
  .col-md-6.col-sm-12 {
    padding: 0;
    .row {
      width: 100%;
      div {
        padding: 0;
        width: 100%;
        display: flex;
        justify-content: flex-start;
      }
      button {
        width: auto;
        padding: 0;
      }
    }
  }
  display: flex;
  flex-direction: column;
  .row {
    margin: auto;
  }
  .contact-body {
    padding: 5% 9% 5% 9%;
    flex: 1;
    .add-gap {
      display: flex;
      flex-direction: column;
      gap: 3em;
    }
  }
  .contact-footer {
    display: flex;
    font-size: 0.6875em;
    justify-content: space-around;
    align-items: center;
    height: 4.125em;
    border-top: 1px solid #ffdbb9;
    .footer-mandatory-links {
      display: flex;
      gap: 10em;
    }
  }

  .btn.btn-primary {
    background: #3c342c !important;
    border-color: #ffdbb9;
    border-radius: 0;
    width: auto;
    &:hover {
      background: #f0ccaa !important;
      color: #6a5e57;
    }
    &:active {
      background: #e8c29f !important;
      border-color: black;
      color: #6a5e57;
    }
  }
  .coming-soon {
    border-radius: 20px;
    background: rgb(255, 240, 227); //Gradient Background because why not?
    background: linear-gradient(
      -195deg,
      hsl(0, 0%, 95%) 0%,
      hsl(0, 0%, 88%) 50%,
      hsl(0, 0%, 87%) 60%,
      hsl(0, 0%, 88%) 70%,
      hsl(0, 0%, 95%) 100%
    );
    background-size: 450% 450%;
    -webkit-animation: Animated-Gradient 18s ease-in infinite; //Browser Support
    -moz-animation: Animated-Gradient 18s ease-in infinite; //Browser Support
    animation: Animated-Gradient 18s ease-in infinite;
  }
}

@-webkit-keyframes Animated-Gradient {
  0% {
    background-position: 10% 0%;
  }
  50% {
    background-position: 30% 0%;
  }
  100% {
    background-position: 10% 0%;
  }
}
@-moz-keyframes Animated-Gradient {
  0% {
    background-position: 10% 0%;
  }
  50% {
    background-position: 91% 100%;
  }
  100% {
    background-position: 10% 0%;
  }
}
@keyframes Animated-Gradient {
  0% {
    background-position: 10% 0%;
  }
  50% {
    background-position: 91% 100%;
  }
  100% {
    background-position: 10% 0%;
  }
}

.contact-page-form {
  width: 75%;
  display: flex;
  gap: 2em;
  flex-direction: column;
  input,
  textarea {
    border-color: #3c342c;
    border-radius: 0;
    &:focus {
      font-weight: 600;
      border-color: #c79a6f;
      -moz-box-shadow: inset 0 0 4px rgba(197, 141, 109, 0.608);
      -webkit-box-shadow: inset 0 0 4px rgba(197, 141, 109, 0.2);
      box-shadow: 0 0 4px rgba(197, 141, 109, 0.648);
    }
  }
}

.pages-services-component {
  width: 100%;
  flex-wrap: wrap;
  display: flex;
  justify-content: space-around;

  .responsiveness {
    display: flex;
    flex-direction: column;
    strong {
      width: 6.125em;
    }
  }
}

.social-media-icons {
  height: 4.375em;
  width: 4.375em;
  border-radius: 100%;
  border: 1px solid #ffdbb9;
  .icon {
    font-size: 1.5em;
  }
}
