.product-individual {
  display: flex;
  flex-direction: column;
}
.section-one,
.section-two {
  display: flex;
  flex-wrap: wrap;
  height: 50em;
  .section-one-carousel {
    width: 50%;
    height: 100%;
  }
  .section-one-information {
    display: flex;
    flex-direction: column;
    padding: 0 5em;
    width: 50%;
    height: 100%;
    justify-content: space-between;
  }
  .section-two-description {
    display: flex;
    flex-direction: column;
    padding: 0 5em;
  }
  .section-two-image {
    padding: 0;
  }
}

@media screen and (max-width: 1200px) {
  .section-one,
  .section-two {
    height: auto !important;
    .section-one-carousel,
    .section-one-information {
      padding: 0;
      width: 100%;
    }
    .section-two-description {
      padding: 5em 0;
      .sub-one {
        padding-bottom: 4em;
      }
    }
  }
}
