.com-dom-buttons.col {
  div {
    display: flex;
    gap: 1em;
    flex-direction: column;
    button {
      width: 8.125em;
      display: block;
      border-radius: 50px;
      background-color: transparent;
      border: 1px solid black;
      padding: 0.5em 1em;
      color: rgb(46, 46, 46);

      font-weight: 600;

      // transition: 250ms ease-in-out;
      // &:hover {
      //   // background: #6a5e57;
      //   // color: #ffdbb9;
      //   border: 1px solid transparent;
      // }
    }
  }
}
