.how-it-works {
  margin: 10em 0em;

  p:nth-child(1) {
    font-size: 2.5em;
    font-weight: 600;
  }

  p:nth-child(2) {
    font-size: 1.5em;
  }
}

.card-controller {
  display: flex;
  justify-content: center;
  margin-bottom: 10em;

  .control-gap {
    gap: 10%;
  }
}

.install-card {
  border: 1px solid rgb(184, 184, 184);
  .install-card-tag {
    padding: 0.5em 0em;
    justify-content: center;
    align-items: center;
    background-color: rgb(70, 57, 49);
    color: white;
    margin: 0;

    p {
      font-size: 1.7em;
      font-weight: 600;
    }
  }

  .install-first-card {
    padding: 5em 0em;
    border-bottom: 1px solid rgb(76, 76, 76);
    .inner-body {
      padding: 0em 2em;
      p:nth-child(1) {
        font-size: 3em;
        font-weight: 600;
      }
      p:nth-child(2) {
        font-size: 1.5em;
      }
    }
  }

  .install-second-card {
    padding: 5em 0em;
    .inner-body {
      padding: 0em 2em;
      p:nth-child(1) {
        font-size: 3em;
        font-weight: 600;
      }
      p:nth-child(2) {
        font-size: 1.5em;
      }
      ul li {
        font-size: 1.5em;
      }
    }
  }
}
