.fade-out {
  opacity: 0;
  transition: opacity 0.5s ease-out;
}

.exit-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3rem;
  height: 3rem;
  border-color: red;
  background: red;
}

.image-container {
  position: relative;
  display: inline-block;
}

.image-container img {
  transition: opacity 0.3s ease;
}

.image-container:hover img {
  opacity: 0.3;
  cursor: pointer;
}
