.project-page {
  padding: 10% 0;
  header {
    display: flex;
    align-items: center;
    gap: 1em;
  }

  .line {
    background: rgb(154, 154, 154);
    height: 1px;
    width: 100%;
    margin: 2em 0em;
  }
  .project-content {
    margin: 0;
    padding: 1em 0;
  }
}

.next-project-info {
  flex-direction: column;
  justify-content: center;
  color: white;
}

.next-project-info header:nth-child(1) {
  font-size: 2rem;
}

.next-project-info header:nth-child(2) {
  font-size: 4rem;
  font-weight: 600;
}

.project-list-item {
  font-size: 1rem;
  padding-bottom: 20px;

  #project-title {
    font-size: 1.6em;
    font-weight: 600;
    color: rgb(68, 68, 68);
    cursor: default;
  }

  #project-title-caption {
    // font-style: italic;
    color: rgb(99, 99, 99);
    font-size: 1.4em;
    cursor: default;
  }
}

.featured-project {
  position: relative;

  margin: 3em 0em;

  div {
    background-color: rgba(241, 241, 241, 0.806);
    border-radius: 7px;
    padding: 2px 20px;

    position: absolute;
    bottom: 20px;
    left: 20px;

    font-size: 0.9em;
    font-weight: 600;
    letter-spacing: 1px;

    cursor: default;
  }
}
.featured-project-container {
  margin: 5em 0em;

  .featured-project-caption {
    #title {
      font-size: 2.8em;
      font-weight: 600;
      color: rgb(65, 63, 63);
    }

    #arrow-container {
      padding: 0.5em 0em;
    }

    #arrow-container a:before {
      border-radius: 50%;

      background-color: #3c342c;
      color: #ffdbb9;
      padding: 1em;
    }
  }
}
