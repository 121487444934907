.navigation-page {
  display: flex;
  align-items: center;
  align-content: center;
  padding: 1.25em 9% 10em;
  width: 100%;
  a {
    text-decoration: none;
    display: block;
    color: black;
    padding: 10%;
  }
  .homepage-quicklinks {
    font-weight: 500;
  }
  .logo {
    font-size: 2.3125em;
    // margin-left: 2em;
  }
  .icons {
    width: 100%;
    // font-size: 1.5em;
    cursor: pointer;
    display: flex;
    // width: 5em;
    align-items: center;
    justify-content: space-between;

    .fa-bars {
      font-size: 1.4em;
      padding: 0.5em 0.5em;
      &:hover {
        background: rgb(231, 231, 231);
        border-radius: 10px;
      }
      &:active {
        outline: 1px dashed rgb(99, 99, 99);
      }
    }
    // .fa:hover {
    //   background: #ffdbb9;
    //   border-radius: 0.3em;
    // }
    .fa-search {
      height: 2em;
      width: 2em;
      font-size: 1.5em;
      cursor: pointer;
      &:hover {
        background: rgb(231, 231, 231);
        border-radius: 10px;
        &:hover {
          background: rgb(231, 231, 231);
          border-radius: 10px;
        }
        &:active {
          outline: 1px dashed rgb(99, 99, 99);
        }
      }
    }
  }
}
