.services-components {
  .services-list {
    padding: 10em 0em;
    //
    .self {
      border-top: 1px solid #a3a3a3;
    }
    //
    .list-items {
      justify-content: space-between;
      align-items: center;
      padding: 3em 0em;

      border-bottom: 1px solid #a3a3a3;
      & a {
        text-decoration: none;
        color: rgb(63, 63, 63);
        font-size: 2em;
      }

      #arrow-container a:before {
        padding: 0.5em;

        font-size: 0.7em;
        color: #3c342c;

        border-radius: 50%;
        border: 1px solid #3c342c;
      }
    }
  }
}

.services-page {
  padding: 0% 9%;

  #hero-title {
    font-size: 3.5em;
    font-weight: 600;
    letter-spacing: 1px;
    padding-bottom: 0.5em;
  }

  p {
    font-size: 1.5em;
  }
}

$services-caption-breakpoint: 488px;

@media screen and (max-width: $services-caption-breakpoint) {
  .services-page {
    .services-caption p {
      width: 100%;
    }
  }
}
