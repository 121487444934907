.body-controller {
  .hero-section {
    padding-bottom: 6em;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    margin: 0;

    // border-bottom: 1px solid black;
    color: rgb(51, 51, 51);

    .hero-control header {
      font-weight: 600;
      padding-bottom: 0.5em;
    }

    .hero-control p {
      font-size: 1.5em;
      color: rgb(87, 87, 87);
    }
  }

  .parent-container {
    .image-container-c1 img {
      padding: 0;
    }

    .projects-involved {
      padding-top: 1em;
      border-bottom: 1px solid black;
      align-items: center;

      & div.col {
        padding: 0.2em 0em;
      }
      & div.col:nth-child(1) {
        display: flex;
        padding-left: 3rem;
        align-items: center;

        font-size: 3.5em;
        border-right: 1px solid black;
      }

      & div.col:nth-child(2) {
        font-size: 1.5em;
        padding-left: 4em;
      }
    }

    .products-involved {
      padding: 5em 0em;
    }

    .products-involved .PI-c {
      display: flex;
      flex-direction: column;
      justify-content: center;
      & div {
        padding-left: 3rem;
      }

      & div:nth-child(1) {
        font-size: 2.5em;
      }

      & div:nth-child(2) {
        font-size: 1.5em;
      }
    }
  }
}
