.products-page {
  padding: 2% 9%;
}

.product-component-buttons {
  display: flex;
  gap: 0.5em;
  flex-direction: row;
  align-items: center;
  a,
  button {
    border: none;
    flex-direction: column;
    &:hover {
      background: #ffdbb9 !important;
      color: #3a3a3a !important;
    }
  }
  #manual,
  #motorised {
    background: white;
    color: #3a3a3a;
  }
  #vertilux,
  #grid {
    background: #e9e9e9;
    color: #bb8a17;
  }
}
