@import "bootstrap/scss/bootstrap";

*, *::before, *::after {
  box-sizing: border-box;
}


* {
  padding: 0;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  font-family: "Cabin", system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

body {
  background-color: #f8f8f8;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.heading-1 {
  font-size: 3.4375em;
}
.heading-2 {
  font-size: 1.5625em;
}
.heading-3 {
  font-size: 1.375em;
}
.body-font {
  font-size: 1.3em;
}

$grid-breakpoints: (
  xs: 0,
  sm: 500px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
);

@include media-breakpoint-only(xs) {
  html {
    font-size: 8px;
  }
  .image-card-style-on-vpc {
    padding: 5% 0;
  }
  .body-font {
    font-size: 2em;
  }
  .canvas-width {
    width: 100vw !important;
  }
  .homepage-quicklinks {
    display: none;
  }
  .contact-page-form {
    width: 100% !important;
  }
  .pages-services-component {
    .responsiveness {
      width: 100%;
      font-size: 1em;
      flex-direction: row !important;
      align-items: center !important;
    }
    .responsiveness-2 {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
    }
  }
}

@include media-breakpoint-down(sm) {
  .canvas-width {
    width: 100vw !important;
  }
  .homepage-quicklinks {
    display: none;
  }
  .contact-page-form {
    width: 100% !important;
  }
  .canvas-width {
    width: 100vw !important;
  }
  .footer-body .to-column {
    flex-direction: column !important;
    padding: 5em 0em;
  }
  .pages-services-component {
    .responsiveness {
      width: 100%;
      font-size: 1.2em;
      flex-direction: row !important;
      align-items: center !important;
    }
    .responsiveness-2 {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      // flex-direction: column;
    }
    .responsiveness-3 {
      padding: 5em 0em;
      flex-direction: column;
    }
  }
}

@include media-breakpoint-only(sm) {
  html {
    font-size: 9px;
  }
  .image-card-style-on-vpc {
    padding: 3% 0;
  }
}

@include media-breakpoint-only(md) {
  html {
    font-size: 10px;
  }
  .image-card-style-on-vpc {
    padding: 1% 0;
  }
}

@include media-breakpoint-up(md) {
  .image-template-size {
    height: 300em;
    width: 300em;
  }
  .canvas-width {
    width: 50vw !important;
  }
}

@include media-breakpoint-only(lg) {
  html {
    font-size: 13px;
  }
}

@include media-breakpoint-only(xl) {
  html {
    font-size: 14px;
  }
  .padding-increase {
    padding-left: 11% !important;
    padding-right: 11% !important;
  }
}

@include media-breakpoint-down(lg) {
  .mobile-version {
    display: block !important;
  }
  .desktop-version {
    display: none !important;
    margin: 0;
  }
}

@include media-breakpoint-only(xxl) {
  html {
    font-size: 14px;
  }
  .padding-increase {
    padding-left: 12% !important;
    padding-right: 12% !important;
  }
}
