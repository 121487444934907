.parent-estimate {
  padding: 0% 12.5%;

  .hero-section {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 250px;
  }

  #estimate-title {
    padding-bottom: 0.5em;
    font-size: 3.5em;
    font-weight: 600;
    letter-spacing: 1px;
  }

  .estimate-description {
    font-size: 1.5em;
  }

  // Option Universal Class
  .option {
    margin: 15em 0em;
    justify-content: space-between;

    gap: 270px;

    #op-1-title {
      font-size: 3em;
      font-weight: 600;
    }

    #op-1 {
      font-size: 1.5em;
    }

    #op-1-config {
      font-size: 2em;
      font-weight: 600;
    }

    #pg-bttn {
      text-decoration: none;
      color: rgb(255, 229, 194);
      border: 1px solid black;
      padding: 0.5em 1em;

      font-weight: 600;
      font-size: 1.3em;
      background-color: rgb(34, 15, 1);
    }

    .kh-ad {
      border: 1px solid rgb(188, 188, 188);
      padding: 2em;
      border-radius: 5px;
    }

    .kh-ad a {
      text-decoration: underline;
      font-size: 1.2em;
      color: black;
      letter-spacing: 1px;
    }
    .kh-logo {
      font-size: 2em;
      font-weight: 600;
    }

    .kh-logo span {
      color: orange;
    }
  }
}
