.contact-us {
  margin: 10em 0em;
  p:nth-child(1) {
    font-size: 2.5em;
    font-weight: 600;
  }

  p:nth-child(2) {
    font-size: 1.5em;
  }

  p:nth-child(3) {
    font-size: 1.5em;
    font-weight: 600;
    letter-spacing: 0.5px;
    margin-bottom: 2em;
  }
}
