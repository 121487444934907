.product-component {
  padding-bottom: 8%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(21em, 1fr));
  grid-template-areas: "side product product product product product";
  .products-section {
    grid-area: product;
    .product-image {
      padding: 4% 0;
      border-top: 1px solid lightgray;
      border-bottom: 1px solid lightgray;
    }
  }
  .heading-2 {
    height: 7rem;
  }
  .sidebar-section {
    height: 100%;
    grid-area: side;
    display: flex;
    .sidebar-section-box {
      border: 1px solid lightgray;
      border-right: none;
      width: 8%;
    }
    .sidebar-section-content {
      position: relative;
      width: 70%;
      padding: 10% 2%;
      .header {
        position: absolute;
        top: -15px;
      }
    }
  }
}

.products-standard-display {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(21em, 1fr));
  gap: 3em;
  .image-template-size {
    height: 20em;
    width: 20em;
  }
  padding-bottom: 10%;
}

.products-display {
  .heading-2 {
    height: 7rem;
  }
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(21em, 1fr));
  gap: 3em;
  .image-template-size {
    height: 20em;
    width: 20em;
  }
  padding-bottom: 10%;
}

@media screen and (max-width: 768px) {
  .product-component {
    grid-template-columns: 1fr;
    grid-template-areas:
      "side"
      "product";
  }
  .product-component .sidebar-section {
    padding-bottom: 10% !important;
    .sidebar-section-content .header {
      top: -8px;
    }
  }
}
