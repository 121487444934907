.ds-description-col {
  align-items: start;
  padding: 5em 0em 20em 0em;
  gap: 270px;
}

#pg-bttn {
  text-decoration: none;
  color: rgb(255, 229, 194);
  border: 1px solid black;
  padding: 0.5em 1em;

  font-weight: 600;
  font-size: 1.3em;
  background-color: rgb(34, 15, 1);
}

#ds-configurator {
  font-size: 2.5em;
  font-weight: 600;
}
