#back-to-top {
  background: none;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
  overflow: hidden;
  outline: none;
  position: fixed;
  bottom: 100px;
  right: -100px;
  padding: 8px 10px;
  border-radius: 20px 0px 0px 20px;
  border: none;
  background-color: #22201d;
  color: white;
  font-weight: 600;
  z-index: 99;
  transform: scale(0);
  transition: all 400ms ease-in-out;
}

#back-to-top.back-to-top-visible {
  transform: scale(1);
  right: 0px;
}
