.image-template {
  background: rgb(233, 233, 233);
  @media screen and (max-width: 600px) {
    font-size: 10px;
  }
  &:hover {
    color: #ffdbb9;
  }
}

.image-info {
  display: flex;
  justify-content: space-between;
  padding: 2em 0;
  #image-info-link {
    height: 1.5625em;
    border-bottom: 1px solid black;
    a {
      text-decoration: none;
      color: black;
    }
  }
}

.image-card-horizontal {
  .row {
    margin: auto;
    .image-card-text {
      background: rgb(230, 230, 230);
      color: black;
      padding: 5%;
    }
    .image-card-image {
      padding: 0;
    }
  }
}
