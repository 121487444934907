.about-page {
  padding: 0% 9%;

  & #about {
    font-size: 3.5em;
    font-weight: 600;
    letter-spacing: 1px;
  }
}

.about-page-who-we-are,
.about-page-our-work {
  .about-container {
    padding: 6em 0em;
    margin: auto;
    display: flex;
    align-items: center;

    .body-text {
      display: flex;
      flex-direction: column;
      gap: 1em;
    }
    &.mobile-version {
      display: none;
      &.our-work {
        padding: 0;
      }
    }
    &.desktop-version {
      padding: 6em 0em;
      &.our-work {
        padding: 0;
        display: flex;
        justify-content: space-between;
      }
    }
  }
}
