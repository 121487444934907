.canvas-width {
  background-image: linear-gradient(
    45deg,
    hsl(0deg 0% 100%) 16%,
    hsl(32deg 100% 100%) 49%,
    hsl(32deg 100% 99%) 59%,
    hsl(32deg 100% 99%) 66%,
    hsl(32deg 100% 99%) 71%,
    hsl(32deg 100% 98%) 77%,
    hsl(32deg 100% 98%) 82%,
    hsl(32deg 100% 97%) 87%,
    hsl(32deg 100% 97%) 93%,
    hsl(32deg 100% 97%) 100%
  );
  .offcanvas-header {
    display: flex;
    justify-content: space-between;
    font-size: 2em;
    padding: 5% 10% 12% 10%;
    .search-bar {
      display: flex;
      position: relative;
      input {
        font-size: 0.6em !important;
        padding-right: 2em;
        background-color: transparent;
        &::placeholder {
          color: #a48e78;
        }
        width: 15em;
        border: none;
        border-bottom: 2px solid #a48e78;
        border-radius: 0;
      }
      .fa.fa-search {
        color: #a48e78;
        position: absolute;
        right: 1em;
        top: 0.6em;
        font-size: 0.5em;
      }
    }
  }
  .offcanvas-body {
    // font-size: 16px;
    display: flex;
    align-items: space-between;
    flex-direction: column;

    .options {
      display: flex;
      flex-direction: column;
      gap: 2em;
      padding: 0 10%;
      .primary-options {
        display: flex;
        flex-direction: column;
        font-size: 4.2em;
        letter-spacing: 1px;
        font-weight: 600;
      }
      .secondary-options {
        display: flex;
        flex-direction: column;
        font-size: 1.9em;
        // font-weight: 600;
        gap: 5px;
      }
    }
    // .social-media-icons-options {
    //   display: flex;
    //   padding: 0 10%;
    //   width: 0;
    //   gap: 2em;
    //   color: black;
    // }
  }
}
